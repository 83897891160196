import styled from "styled-components"

import { Dialog } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

import { DeviceConfigurationView } from "src/components/Homes/DeviceDetails/Configuration/DeviceConfigurationView"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import CloseIcon from "src/ui/icons/close.svg"
import { spacing } from "src/ui/spacing"

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    align-self: flex-start;
    vertical-align: unset;
    height: 90vh;
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 2px;
  top: 2px;
`

export function DeviceSettingsDialog({
  open,
  onClose,
  onExited,
  devices,
}: {
  open: boolean
  onClose: () => void
  onExited?: () => void
  devices: TDevice[]
}) {
  const { orgId } = useOrganization()
  const ids = devices.map((d) => d.device_id)
  const fetchDevices = useFetchDevices({
    orgId: orgId,
    filter: { ids, limit: ids.length },
    options: {
      enabled: ids.length > 0,
    },
  })
  const fetchedDevices = fetchDevices.data?.devices || []

  const handleExited = () => {
    onExited && onExited()
  }

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      scroll="paper"
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: handleExited }}
      aria-labelledby="configure-devices-dialog-title"
      aria-describedby="configure-devices-dialog-description"
    >
      <div style={{ paddingRight: spacing.M }}>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon width={24} />
        </StyledIconButton>
      </div>

      <div style={{ paddingRight: spacing.M }}>
        <DeviceConfigurationView title="Settings" devices={fetchedDevices} />
      </div>
    </StyledDialog>
  )
}
