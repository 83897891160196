import { useMemo, useState } from "react"
import styled from "styled-components"

import { Badge } from "@material-ui/core"
import { useMediaQuery } from "usehooks-ts"

import { DeviceSettingsDialog } from "src/components/DeviceSettingsDialog/DeviceSettingsDialog"
import {
  NoSensors,
  NoSensorsOnFilter,
} from "src/components/EmptyState/NoSensors"
import { FilterQueryBox } from "src/components/Filter/FilterQueryBox"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { breakpoint } from "src/constants/breakpoints"
import { usePostDeviceSettingsDialogInitiated } from "src/data/analytics/queries/deviceSettingsAnalyticsQueries"
import {
  useFetchDeviceCount,
  useFetchDevices,
} from "src/data/devices/queries/deviceQueries"
import {
  LowBatteryFilter,
  OfflineDeviceFilter,
} from "src/data/filters/filtersDevice"
import { HOME_MAX_LIMIT } from "src/data/homes/logic/homeConstants"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useUrlParamBoolean } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MainView } from "src/ui/Layout/MainView"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

import { DeviceTable, IDeviceWithHome } from "./DeviceTable"

const MOBILE_LAYOUT = breakpoint.normalDown

export function DeviceList() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const orgId = org.id

  const [showSettings, setShowSettings] = useState(false)
  const postDeviceSettingsDialogInitiated =
    usePostDeviceSettingsDialogInitiated()

  const renderStackedRows = useMediaQuery(`(${MOBILE_LAYOUT})`)

  const { limit, offset, setOffset } = useUrlPager()
  const { value: offline } = useUrlParamBoolean(OfflineDeviceFilter.id)
  const { value: low_battery } = useUrlParamBoolean(LowBatteryFilter.id)

  const fetchDevices = useFetchDevices({
    orgId,
    filter: { limit, offset, offline, low_battery },
    options: { keepPreviousData: false },
  })

  const fetchDeviceCount = useFetchDeviceCount({
    orgId,
  })
  const deviceCountTotal = fetchDevices.data?.paging.total_count ?? 0

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      ids: fetchDevices.data?.devices?.map((d) => d.home),
      limit: HOME_MAX_LIMIT,
    },
    options: { enabled: !!fetchDevices.data?.devices },
  })

  const devicesPage: IDeviceWithHome[] = useMemo(() => {
    const devicesWithHome: IDeviceWithHome[] =
      fetchDevices.data?.devices.map((device) => {
        const home = fetchHomes.data?.homes.find(
          (h) => h.home_id === device.home
        )
        return {
          ...device,
          homeObj: {
            home_id: device.home,
            name: home?.name ?? "",
            timezone: home?.timezone || "etc/utc", // remove the hardcoding of timezone when we move this to a hook
          },
        }
      }) ?? []

    return devicesWithHome
  }, [fetchDevices.data?.devices, fetchHomes.data?.homes])

  const [selectedDevices, setSelectedDevices] = useState<IDeviceWithHome[]>([])
  const selectedDeviceIds = useMemo(
    () => new Set(selectedDevices.map((d) => d.device_id)),
    [selectedDevices]
  )

  function onCheckboxClick(device: IDeviceWithHome, checked: boolean) {
    if (checked) {
      setSelectedDevices((prev) => Array.from(new Set([...prev, device])))
    } else {
      setSelectedDevices((prev) => {
        return prev.filter((d) => d.device_id !== device.device_id)
      })
    }
  }

  const headers = useMemo(() => {
    const allSelected =
      !!devicesPage.length &&
      devicesPage.every((d) => selectedDeviceIds.has(d.device_id))

    function onSelectAll(checked: boolean) {
      if (checked) {
        setSelectedDevices((prev) =>
          Array.from(new Set([...prev, ...devicesPage]))
        )
      } else {
        setSelectedDevices((prev) => {
          const unselectCurrentPage = prev.filter(
            (selectedDevice) =>
              !devicesPage.find((d) => d.device_id === selectedDevice.device_id)
          )
          return unselectCurrentPage
        })
      }
    }

    return [
      <div key={"selected"}>
        <MCheckbox checked={allSelected} onCheck={onSelectAll} />
      </div>,
      <div key={"sensor"}>{t(langKeys.sensor)}</div>,
      <div key={"home"}>{t(langKeys.home)}</div>,
      <div key={"signal"}>{t(langKeys.signal)}</div>,
      <div key={"battery"}>{t(langKeys.battery)}</div>,
      <div key={"status"}>{t(langKeys.status)}</div>,
      <div key={"mounting-plate"}>{t(langKeys.mounting_plate)}</div>,
    ]
  }, [devicesPage, langKeys, selectedDeviceIds, t])

  const disableDeviceSettingsButton = selectedDevices.length === 0
  const variant = disableDeviceSettingsButton ? "subtle" : "primary"

  const filtersApplied = !!offset || offline || low_battery

  const showNoSearchResults =
    filtersApplied && fetchDevices.data?.paging.total_count === 0

  // The reason we are using `isLoading` is to make sure we render this global loading state even when the device count is disabled
  // `isInitialLoading` would not stop render here and instead continue
  if (fetchDeviceCount.isLoading) {
    return (
      <DeviceListLayout>
        <MSkeleton height={30} />
        <MSkeleton height={30} />
        <MSkeleton height={30} />
      </DeviceListLayout>
    )
  }

  if (fetchDeviceCount.data === 0) {
    return (
      <DeviceListLayout>
        <NoSensors />
      </DeviceListLayout>
    )
  }

  return (
    <DeviceListLayout
      actionBar={
        <ActionMenu>
          {selectedDeviceIds.size > limit && (
            <MButton onClick={() => setSelectedDevices([])} variant="minimal">
              {t(langKeys.placeholder_web, {
                text: "Clear all selected sensors",
              })}
            </MButton>
          )}
          <Badge badgeContent={selectedDeviceIds.size}>
            <MButton
              onClick={() => {
                postDeviceSettingsDialogInitiated.mutate()
                setShowSettings(true)
              }}
              disabled={disableDeviceSettingsButton}
              variant={variant}
              hidden={renderStackedRows}
            >
              {t(langKeys.settings)}
            </MButton>
          </Badge>
        </ActionMenu>
      }
    >
      <DeviceSettingsDialog
        open={showSettings}
        onClose={() => setShowSettings(false)}
        devices={selectedDevices}
      />
      <FilterQueryBox
        dropdownFilters={[LowBatteryFilter, OfflineDeviceFilter]}
        bottomMargin={spacing.L}
      />

      <DeviceTable
        linkBasePath="sensors"
        selectedDeviceIds={selectedDeviceIds}
        onCheckboxClick={onCheckboxClick}
        headers={headers}
        data={devicesPage || []}
        loading={fetchDevices.isLoading}
        expectedNbrRows={limit}
      />

      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={deviceCountTotal}
        />
      </PagerWrapper>
      {showNoSearchResults && <NoSensorsOnFilter />}
    </DeviceListLayout>
  )
}

function DeviceListLayout({
  actionBar,
  children,
}: {
  actionBar?: React.ReactNode
  children: React.ReactNode
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MainView
      title={t(langKeys.sensor, { count: 2 })}
      titleBarProps={{
        actionBar,
      }}
      size="unset"
    >
      {children}
    </MainView>
  )
}

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const ActionMenu = styled.div`
  margin-left: auto;
  display: flex;
  gap: ${spacing.M};
`
