import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TDeviceSettingsEventTypes =
  "Device Monitoring Settings Dialog Initiated"

function usePostDeviceSettingsTrackingEvent<TEventProperties>(
  event: TDeviceSettingsEventTypes
) {
  return usePostTrackEventWithParams<
    TDeviceSettingsEventTypes,
    TEventProperties
  >({
    event,
  })
}

export function usePostDeviceSettingsDialogInitiated() {
  const x = usePostDeviceSettingsTrackingEvent(
    "Device Monitoring Settings Dialog Initiated"
  )
  return { ...x, mutate: () => x.mutate({}) }
}
